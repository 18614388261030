import { useSelector, useDispatch } from "react-redux";
import { store } from "../../redux/store";
import { TextField, Button, Typography, Box, Icon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { nameTransform } from "../../utils/utilsFunctions";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import IwdDataGrid from "../../pages/IwdDataGrid";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import AssociatedValve from "./AssociatedValve";
import theme from "../../theme";
import ModalDialog from "../meters/ModalDialog";
import { setMessage } from "../../redux/slices/snackbarSlice";
import DeleteButton from "../../utils/DeleteButton";
import ValveShow from "./ValveShow";
const MeterValveTab = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const [getMeterShow] = store.useLazyGetMeterShowQuery();
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataByNameQuery();
  const [assocValve, { isSuccess: assocSuccess }] =
    store.useAddCompoundTwinMutation();

  const [page, setPage] = useState(1);
  const parentMeterTwinId = useSelector((state) => state?.meterSlice?.meterShow)
    ?.attributes?.fields?.digital_twin_id;
  const parentMeterId = useSelector(
    (state) => state?.meterSlice?.meterShow
  )?.id;
  const meterShow = useSelector((state) => state?.meterSlice?.meterShow);
  const per_page = 10;
  const { t } = useTranslation();
  const [serial, setSerial] = useState("");

  const valveMeters = result?.data?.data;
  const count = result?.data?.meta?.count;
  const valve = meterShow?.attributes?.fields?.compound_twins?.filter(
    (item) => {
      debugger;
      return window?.VALVE_ID.map(Number).includes(
        Number(item?.digital_twin_id)
      );
    }
  );

  const params = {
    digital_twin_ids: window.VALVE_ID,
    mechanical_serial_number: serial,
    page: page,
    per_page: per_page,
    without_parent: true,
  };
  const onSearchClick = () => {
    trigger(["altior/device", { ...params }]);
  };

  useEffect(() => {
    trigger(["altior/device", { ...params }]);
  }, [page]);

  const onAssocClick = (id) => {
    const attributes = {
      device_id: +id,
    };
    assocValve({
      attributes: attributes,
      parentId: parentMeterId,
      parentTwinId: parentMeterTwinId,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2,
    },
    {
      field: "network_address",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3,
      editable: false,
    },
    {
      field: "serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "condominium_name",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),

      sortable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("configuration_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_reeading_date",
      headerName: t("last_reeading_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Tooltip title={t("assoc_valve")}>
              <IconButton onClick={() => onAssocClick(params?.value)}>
                <AddIcon sx={{ fill: theme.palette.success.main }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];
  const rows = valveMeters?.map((item) => ({
    id: item.id ? item.id : "--",

    class: item?.attributes?.class
      ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--",
    network_address:
      item?.attributes?.fields?.[item?.attributes?.fields?.identifier_type],
    serial: item?.attributes?.fields?.serial
      ? item?.attributes?.fields?.serial
      : "--",
    /*   metrological_serial: item?.attributes?.fields?.serial ?? "--", */
    condominium_name: item?.attributes?.fields?.condominium_name ?? "--",
    apartment_name: item?.attributes?.fields?.apartment_name ?? "--",
    last_reeading_date: item?.attributes?.fields?.last_reading?.datetime_utc
      ? t("dt", {
          val: new Date(item?.attributes?.fields?.last_reading?.datetime_utc),
        })
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("date_val", { val: new Date(item?.attributes?.inserted_at) })
      : "--",
    actions: item?.id,
  }));

  useEffect(() => {
    if (assocSuccess) {
      dispatch(setMessage(t("assoc_valve_success")));
      getMeterShow(parentMeterId);
    }
  }, [assocSuccess]);

  useEffect(() => {
    console.log("parentMeterTwinId", parentMeterTwinId);
  }, [parentMeterTwinId]);

  return (
    <>
      {valve?.length > 0 && (
        <ValveShow
          valveId={valve?.[0]?.digital_twin_instance_id}
          parentTwinId={parentMeterTwinId}
        />
      )}
      {(valve?.length == 0 || !valve) && (
        <Box>
          <Typography variant="h5">{t("no_associated_valve")}</Typography>
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
            label={t("mechanical_serial_number")}
          />
          <Box
            sx={{ mt: 1 }}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button onClick={onSearchClick} disabled={!serial}>
              {t("search")}
            </Button>
          </Box>
          <Box sx={{ mt: 3 }}>
            <IwdDataGrid
              columns={columns}
              perPage={per_page}
              setPage={setPage}
              rows={rows ?? []}
              page={page}
              count={count}
              paginationMode={"server"}
              sort={false}
              height={545}
              tableName={"meters"}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MeterValveTab;
