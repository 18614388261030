// The difference with this component is that it includes a role check.
// If the user is an admin, they will be able to select from all roles.
// If the user is not an admin, they can only choose from the roles assigned to them.

import { store } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const RolesWidget = (props) => {
  const admins = ["/admin", "admin"];
  const [value, setValue] = useState([]);
  const userRoles = useSelector((state) => state?.userPreferenceSlice?.roles);
  const allRoles = useSelector((state) => state?.aegisSlice?.roles);
  const isAdmin = userRoles?.some((role) => admins.includes(role));
  const [getRoles] = store.useLazyGetAllrolesQuery();

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (isAdmin) {
      getRoles();
    }
  }, [isAdmin]);
  useEffect(() => {
    if (!isAdmin) {
      setOptions(
        userRoles?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    } else if (isAdmin && allRoles?.length > 0) {
      setOptions(
        allRoles?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [isAdmin, allRoles]);
  useEffect(() => {
    if (value?.length >= 0) {
      props.onChange(value?.map((item) => item?.value));
    }
  }, [value]);

  useEffect(() => {
    console.log("valuerolix", value);
  }, [value]);

  useEffect(() => {
    if (props?.schema?.default) {
      setValue(
        props?.schema?.default?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [props?.schema?.default]);

  const selectedRoles = value?.map((item) => item?.label);

  return (
    <>
      <Autocomplete
        multiple
        fullWidth
        disablePortal
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        id="combo-box-demo"
        options={
          options?.length > 0
            ? options?.filter((item) => !selectedRoles?.includes(item?.label))
            : []
        }
        renderInput={(params) => (
          <TextField
            error={props?.rawErrors?.length > 0}
            {...params}
            label="roles"
          />
        )}
      />
    </>
  );
};

export default RolesWidget;
