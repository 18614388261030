import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import TimerIcon from "@mui/icons-material/Timer";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import { useParams } from "react-router-dom";
import theme from "../../../theme";
import { Link } from "react-router-dom";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import ModalDialog from "../../meters/ModalDialog";

const DevicePolicyShowCard = ({ params }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [mode, setMode] = useState(null);
  const [exitModal, setExitModal] = useState(false);
  const data = useSelector((state) => state?.policySlice?.devicePolicyShow);
  const { id, deviceId } = useParams();
  const userShow = useSelector((state) => state?.userSlice?.userShow);
  const [getDevicePolicyShow] = store.useLazyGetDevicePolicyShowQuery();
  const [getDevicePolicyEvents] = store.useLazyGetDevicePolicyEventsQuery();
  const user = `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`;
  const [openValve, { isSuccess }] = store.useOpenValveMutation();
  const policyShow = useSelector((state) => state?.policySlice.policyShow);
  const [getPolicyShow] = store.useLazyGetPolicyShowQuery();
  const [closeValve, { isSuccess: closeSuccess }] =
    store.useCloseValveMutation();
  const [exitBypass, { isSuccess: exitBypassSuccess }] =
    store.useExitBypassMutation();
  const threshold = useSelector(
    (state) => state?.policySlice?.policyShow
  )?.consumption_threshold;
  const now = new Date();
  const startDate = new Date(policyShow?.start_date);
  const endDate = new Date(policyShow?.end_date);
  const isActive = now >= startDate && now <= endDate;
  const interruptedDate = policyShow?.interrupted
    ? new Date(policyShow?.interrupted)
    : null;

  const { t } = useTranslation();

  const userId = useSelector(
    (state) => state?.policySlice?.policyShow
  )?.user_id;

  const onBypassOpen = () => {
    openValve({
      policyId: id,
      devicePolicyId: deviceId,
      attributes: {},
    });
  };
  const onBypassClose = () => {
    closeValve({
      policyId: id,
      devicePolicyId: deviceId,
      attributes: {},
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("bypass_opened")));
      getDevicePolicyEvents({
        policyId: id,
        devicePolicyId: deviceId,
        params: {
          ...params,
        },
      });
      setModal(false);
      setMode(null);
      getDevicePolicyShow({ policyId: id, devicePolicyId: deviceId });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (closeSuccess) {
      dispatch(setMessage(t("bypass_closed")));
      getDevicePolicyEvents({
        policyId: id,
        devicePolicyId: deviceId,
        params: {
          ...params,
        },
      });
      setModal(false);
      setMode(null);
      getDevicePolicyShow({ policyId: id, devicePolicyId: deviceId });
    }
  }, [closeSuccess]);

  useEffect(() => {
    if (!threshold) {
      getPolicyShow(id);
    }
  }, [threshold]);

  const okStatus = data?.state == "ok";
  const closeModal = () => {
    setModal(false);
  };

  const bypassTitle = mode == "open" ? t("open_bypass") : t("close_bypass");
  const bypassText =
    mode == "open" ? t("open_bypass_text") : t("close_bypass_text");

  const onModalConfirm = () => {
    if (mode == "open") {
      onBypassOpen();
    }
    if (mode == "close") {
      onBypassClose();
    }
  };
  const onOpenClick = () => {
    setModal(true);
    setMode("open");
  };
  const onCloseClick = () => {
    setModal(true);
    setMode("close");
  };
  const openExitModal = () => {
    setExitModal(true);
  };
  const closeExitmodal = () => {
    setExitModal(false);
  };
  const onBypassExitClick = () => {
    exitBypass({
      policyId: id,
      devicePolicyId: deviceId,
      attributes: {},
    });
  };

  useEffect(() => {
    if (exitBypassSuccess) {
      dispatch(setMessage(t("exit_bypass_success_message")));
      getDevicePolicyShow({ policyId: id, devicePolicyId: deviceId });
      closeExitmodal();
    }
  }, [exitBypassSuccess]);
  return (
    <>
      <ModalDialog
        open={exitModal}
        title={t("exit_bypass_title")}
        close={closeExitmodal}
      >
        <Box>
          <Typography>{t("exit_bypass_text")}</Typography>
          <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"space-between"}>
            <Button onClick={closeExitmodal}>{t("no")}</Button>
            <Button onClick={onBypassExitClick}>{t("yes")}</Button>
          </Box>
        </Box>
      </ModalDialog>
      <ModalDialog open={modal} title={bypassTitle} close={closeModal}>
        <Box>
          <Typography>{bypassText}</Typography>
          <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"space-between"}>
            <Button onClick={closeModal}>{t("no")}</Button>
            <Button onClick={onModalConfirm}>{t("yes")}</Button>
          </Box>
        </Box>
      </ModalDialog>
      <Card
        sx={{
          width: "100%",
          margin: "20px auto",
          padding: 3,
          boxShadow: 3,
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={3}>
            {/* Header */}
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      fontSize: { xs: "20px", sm: "24px", md: "28px" },
                    }}
                  >
                    {t("device")}: {data?.serial_number}
                  </Typography>
                  <Chip
                    label={data?.state.replace("_", " ")}
                    color={okStatus ? "success" : "primary"}
                    icon={<AccessTimeIcon />}
                    sx={{
                      fontSize: { xs: "12px", sm: "14px", md: "16px" },
                      padding: "4px 8px",
                    }}
                  />
                </Box>
                {isActive && !interruptedDate && (
                  <Box display="flex" gap={2}>
                    {(data?.state == "bypass_open" ||
                      data?.state == "bypass_close") && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={openExitModal}
                      >
                        {t("exit_bypass_title")}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onOpenClick}
                    >
                      {t("open_bypass")}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={onCloseClick}
                    >
                      {t("close_bypass")}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>

            {/* Device Details */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" gap={1}>
                <TimerIcon sx={{ color: "gray", fontSize: 20 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("valve_state")}:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data?.valve_state}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1} mt={2}>
                <DeviceHubIcon sx={{ color: "gray", fontSize: 20 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("device")}
                </Typography>
                <Link to={`/meters/${data?.device_id}`}>
                  <IconButton>
                    <VisibilityIcon
                      sx={{ color: theme.palette.navIcon, fontSize: 20 }}
                    />
                  </IconButton>
                </Link>
              </Box>
            </Grid>

            {/* Threshold and Device Link */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" gap={1}>
                <TimerIcon sx={{ color: "gray", fontSize: 20 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("consumption_threshold")}:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {threshold} L
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1} mt={2}>
                <LanguageIcon sx={{ color: "gray", fontSize: 20 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("timezone")}:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data?.timezone}
                </Typography>
              </Box>
            </Grid>

            {/* Inserted At */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" gap={1}>
                <TimerIcon sx={{ color: "gray", fontSize: 20 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("updated_at")}:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t("dt", {
                    val: new Date(data?.updated_at),
                  })}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" gap={1} mt={2}>
                <NewReleasesIcon sx={{ color: "gray", fontSize: 20 }} />
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {t("mechanical_serial_number")}:
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {data?.mechanical_serial_number}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default DevicePolicyShowCard;
