import { StyledDataGrid } from "./StyledDataGrid";
import { useEffect, useTransition } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useState } from "react";
import Box from "@mui/material/Box";
import {
  IconButton,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import theme from "../theme";
const InfiniteTable = ({
  columns,
  rows,
  styles,
  paginationMode,
  page,
  perPage,
  setPage,
  isSuccess,
  records,
  recorsLength,
  recordsFn,
  tableStyles,
}) => {
  const [actualPage, setActualPage] = useState(null);
  function CustomPagination({
    setPage,
    isSuccess,
    parentPage,
    records,
    recordsFn,
    actualPage,
    setActualPage,
    setTotalRows,
    recorsLength,
    perPage,
  }) {
    const dispatch = useDispatch();
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, (state) => state.pagination.page);

    const [newPage, setNewPage] = useState(null);
    let newpage;
    const pageCount = useGridSelector(
      apiRef,
      (state) => state.pagination.pageCount
    );
    useEffect(() => {
      console.log("totalRows", pageCount);
    }, [pageCount]);
    useEffect(() => {}, [actualPage]);
    const handleChange = (event, value) => {
      setPage(value);
      setActualPage(value);

      dispatch(recordsFn("waiting"));
    };
    useEffect(() => {
      if (parentPage && records == "havedata") {
        apiRef?.current.setPage(parentPage - 1);
      }
    }, [parentPage, records]);

    useEffect(() => {
      if (parentPage == 1) {
        /*       apiRef?.current.setPage(1); */
        setPage(1);
        setActualPage(1);
        setTotalRows(Infinity);
      }
    }, [parentPage]);

    useEffect(() => {
      if (records == "nodata") {
        setPage(actualPage);
        apiRef.current.setPage(actualPage);
        setTotalRows(actualPage * perPage);
      }
    }, [records]);
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={handleChange}
        sx={{
          // Общие стили для элементов пагинации
          "& .MuiPaginationItem-root": {
            color: "#000", // Цвет обычных элементов
          },
          // Стили для выбранного элемента
          "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: theme.palette.customPagination, // Цвет фона выбранного элемента
            color: "#fff", // Цвет текста выбранного элемента
          },
          // Стили для стрелок
          "& .MuiPaginationItem-icon": {
            color: "#000", // Цвет стрелок
          },
        }}
      />
    );
  }

  const [totalRows, setTotalRows] = useState(Infinity);

  return (
    <Box sx={{ ...styles }}>
      <StyledDataGrid
        disableColumnMenu
        rows={rows ?? []}
        rowCountText={"..."}
        columns={columns}
        getRowClassName={(params) => tableStyles && tableStyles(params)}
        pageSize={perPage}
        rowsPerPageOptions={[perPage]}
        disableSelectionOnClick
        components={{
          Pagination: () => (
            <CustomPagination
              isSuccess={isSuccess}
              setPage={setPage}
              parentPage={page}
              records={records}
              recordsFn={recordsFn}
              actualPage={actualPage}
              setActualPage={setActualPage}
              setTotalRows={setTotalRows}
              recorsLength={recorsLength}
              perPage={perPage}
            />
          ),
        }}
        paginationMode={paginationMode}
        rowCount={totalRows}
        rowLength={3}
        getRowHeight={() => "auto"}
      />
    </Box>
  );
};

export default InfiniteTable;
