import { Grid, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";

const ManufacturerData = ({ result }) => {
  const { t } = useTranslation();

  const UnitValue = (value) => {
    if (!value || Object?.keys(value)?.length == 0) {
      return "--";
    } else {
      return `${t("number", {
        val: value?.value,
      })} ${value?.unit == "m^3/h" ? "m³/h" : value?.unit}`;
    }
  };

  const fields = result?.data?.data?.attributes?.fields;
  return (
    <>
      <Grid container>
        <Grid
          container
          alignItems="center"
          rowSpacing={5}
          direction="row"
          spacing={5}
          xs={12}
        >
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("diameter")}:
            </Typography>
            <Typography variant="subtitle1">
              {fields.diameter ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Q1:
            </Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q1)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Q2:
            </Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q2)}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Q3:
            </Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q3)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              Q4:
            </Typography>
            <Typography variant="subtitle1">{UnitValue(fields.q4)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              R:
            </Typography>
            <Typography variant="subtitle1">{fields.r ?? "--"}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("temperature_class")}:
            </Typography>
            <Typography variant="subtitle1">
              {fields.temperature_class ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("head_losses")}:
            </Typography>
            <Typography variant="subtitle1">
              {fields.head_loss ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("nominal_pressure")}:
            </Typography>
            <Typography variant="subtitle1">
              {fields.nominal_pressure ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("indicative_weight")}:
            </Typography>
            <Typography variant="subtitle1">
              {UnitValue(fields.weight)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("flow_sensitivity")}:
            </Typography>
            <Typography variant="subtitle1">
              {fields.flow_profile_sensitivity_classes ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("waterproof_class")}:
            </Typography>
            <Typography variant="subtitle1">
              {fields.ip_class ?? "--"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("battery_capacity")}:
            </Typography>
            <Typography variant="subtitle1">
              {UnitValue(fields?.battery)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("manufacturer")}:
            </Typography>
            <Typography variant="subtitle1">{fields.manufacturer}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              {t("name")}:
            </Typography>
            <Typography variant="subtitle1">{fields.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ManufacturerData;
