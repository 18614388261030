import React, { useState, useEffect } from "react";
import { TextField, InputAdornment } from "@mui/material";

const ClosePercentage = (props) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue > 100) {
      inputValue = 100;
    }
    setValue(inputValue);
  };
  useEffect(() => {
    if (value) {
      props.onChange(+value);
    }
  }, [value]);

  useEffect(() => {
    if (props?.schema?.default) {
      setValue(props?.schema?.default);
    }
  }, [props?.schema?.default]);
  return (
    <TextField
      label="Close Percentage"
      value={value}
      error={props?.rawErrors?.length > 0}
      onChange={handleChange}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      fullWidth
      variant="outlined"
    />
  );
};

export default ClosePercentage;
