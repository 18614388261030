import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Button, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { setUserName } from "../../redux/slices/breadCrumbMeterSlice";
import { resetUserShow } from "../../redux/slices/userSlice";
import IwdDataGrid from "../../pages/IwdDataGrid";
import TrashModal from "../TrashModal";
import { store } from "../../redux/store";
import theme from "../../theme";

const Users = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [trashId, setTrashId] = useState(null);
  const users = useSelector((state) => state?.userSlice?.users);
  const rowCount = useSelector((state) => state?.userSlice?.count);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const { t } = useTranslation();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [deleteUser, { isSuccess: deleteSuccess }] =
    store.useDeleteUserMutation();
  const paramsList = {
    page: page,
    per_page: perPage,
  };
  useEffect(() => {
    getUsers(paramsList);
  }, [page, deleteSuccess]);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setUserName(null));
  }, []);
  useEffect(() => {}, [users]);
  useEffect(() => {
    if (deleteConfirmed) {
      deleteUser(trashId);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  const onTrashClick = (id) => {
    dispatch(opendModal());
    setTrashId(id);
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("users"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    dispatch(resetUserShow());
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "first_name",
      headerName: t("first_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "last_name",
      headerName: t("last_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: t("email"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    /*  {
      field: "user_name",
      headerName: t("user_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "display_name",
      headerName: t("display_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "nick_name",
      headerName: t("nick_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    }, */
    {
      field: "roles",
      headerName: t("roles"),
      type: "string",
      sortable: false,

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {params?.value?.map((item) => (
              <Chip
                key={item}
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  color: theme.palette.error.contrastText,
                  margin: "2px",
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onTrashClick(params.value)}
            >
              <Tooltip title={t("delete_user_title")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
            <IconButton sx={{ float: "right" }}>
              <Link to={`/users/${params?.value}`}>
                <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
              </Link>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const tableRows = users?.map((item, index) => ({
    id: index,
    first_name: item?.attributes?.first_name ?? "--",
    last_name: item?.attributes?.last_name ?? "--",
    email: item?.attributes?.email ?? "--",
    user_name: item?.attributes?.username ?? "--",
    display_name: item?.attributes?.display_name,
    nick_name: item?.attributes?.nick_name ?? "--",
    roles: item?.attributes?.roles,
    actions: item?.id,
  }));

  return (
    <>
      {/*  <Typography
        sx={{ mb: 4, }}
        variant="h4"
      >
        {t("users")}
      </Typography> */}
      <TrashModal title={t("delete_user_title")} text={t("delete_user_text")} />
      {tableRows && (
        <Box sx={{ height: "74.6vh" }}>
          <IwdAuthWrapper
            children={
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant={window.BUTTON_TYPE}
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                  onClick={() => navigate("/users/create")}
                >
                  {t("new_user")}
                </Button>
              </Box>
            }
            section={"add_user_btn"}
          />

          <IwdDataGrid
            rows={tableRows}
            columns={columns}
            perPage={perPage}
            setPage={setPage}
            page={page}
            count={rowCount}
            sort={false}
            height={741}
            tableName={"users"}
            paginationMode={"server"}
          />
        </Box>
      )}
    </>
  );
};

export default Users;
